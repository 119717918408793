body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: black;
    background: white;
  }

  p{
    @media (max-width:1280px) {
         font-size:14px
    }
   }

  .font2{
    font-family: $headings-font-family;
  }

  .font3{
    font-family: 'Shadows Into Light', cursive;
  }



  .page-header {
      margin: 50px 0;
      @extend .title_center;
  }

  .btn, a, button {
      transition: .5s;
              outline-style: none;
          outline-width: 0;
          outline-color: transparent;

      &:hover,
      &:focus,
      &:active {
          outline-style: none;
          outline-width: 0;
          outline-color: transparent;
      }
  }

  .btn{
      line-height:1.5 !important;
  }

  .footer {
      text-align: center;
      background-color: white;
      font-size: $font-size-base;
      color: $gray;
      min-height: 25rem;
      display: flex;
      align-items: center;

      border-top: solid 3px $brand-primary;

      a {
          color:black;

          &:hover {
              color: black;
          }
      }

        .footer_address{
          color:black;
      }
      @media(min-width: $screen-sm-min) {
          text-align: left;
      }
  }

  ////////////// boutons

  .btn-fleche {
      @extend .btn-accueil;
      background: $brand-primary;
      white-space: normal;
      position: relative;
      padding: 10px 50px;
      border: 2px solid;
      color: white;
      font-weight: bold;
      border-radius: 50px;
      border: solid 1px $brand-primary;
      //margin-bottom: 50px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;


      &:hover {
          background: white;
          color: $brand-primary;
          border-radius: 50px;
          border: solid 1px $brand-primary;
          padding-right: 70px;
      }

      &::after {
          position: absolute;
          margin-left: 20px;
          content: "\f061";
          font-family: fontAwesome;
          color: $brand-primary;
          -webkit-transition: all .8s ease-out .0s;
          transition: all .8s ease-out .0s;
      }
  }

  .btn-fleche-2 {
      @extend .btn-accueil;
      background: white;
      white-space: normal;
      position: relative;
      padding: 10px 50px;
      border: 2px solid;
      color: $brand-primary;
      font-weight: bold;
      border-radius: 50px;
      border: solid 1px $brand-primary;
      //margin-bottom: 50px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;

      &:hover {
          background: $brand-primary;
          color: white;
          border-radius: 50px;
          border: solid 1px $brand-primary;
          padding-right: 70px;
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
      }

      &::after {
          position: absolute;
          margin-left: 20px;
          content: "\f061";
          font-family: fontAwesome;
          color: white;
          -webkit-transition: all .8s ease-out .0s;
          transition: all .8s ease-out .0s;
      }
  }

  .btn-fleche-inverse {
      @extend .btn-fleche;
      border: 2px solid;
      color: $brand-primary;
      border: solid 1px $brand-primary;

      &:hover {
          background: $brand-primary;
          color: white;

      }

      &::after {
          color: white;
      }
  }

  .btn-perso {
      @extend .btn-accueil;
      background: $brand-primary;
      color: white;
      border: solid 2px white;
      border-radius: 50px;
      padding: 15px 50px;
      margin: 15px auto;

      &:hover {
          background: white;
          color: $brand-primary;
          border-radius: 50px;
          border: solid 2px $brand-primary;
      }

      .fa {
          margin-right: 10px;
      }
  }

  .btn-perso-inverse {
      background: white;
      color: $brand-primary;
      border: solid 1px $brand-primary;
      border-radius: 50px;
      padding: 15px 50px;
      margin: 15px auto;

      .fa {
          margin-right: 10px;
      }
  }

  /* ==========================================================================
      Global
      ========================================================================== */
      .title_left {
          @extend .text-left;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: $brand-primary;
          text-transform: uppercase;
          line-height: 1.5;
          font-family: 'Josefin Sans', sans-serif;
          font-weight:bolder;

          small {
              display: block;
              line-height: 1.5;
          }

          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 4rem;
              height: 4px;
              background-color: $brand-primary;
          }
      }



      .title_left_2 {
          @extend .text-left;
          @extend .title_left;
          color: white;
          font-weight: bolder;

          &::after {
              background-color: black;
          }
      }


      .title_center {
          @extend .text-center;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: $brand-primary;
          line-height: 1.5;

          small {
              display: block;
              line-height: 1.5;
          }

          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 4rem;
              height: 2px;
              transform: translateX(-50%);
              background-color: $brand-primary;
          }
      }

      .title_right {
        @extend .text-right;
        margin: 0 0 20px 0;
        padding: 0 0 25px 0;
        position: relative;
        color: $brand-primary;
        line-height: 1.5;
        font-weight:bolder;

        small {
            display: block;
            line-height: 1.5;
            font-family: 'Shadows Into Light', cursive;
            text-transform: capitalize;
            font-size: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 9rem;
            height: 2px;
            background-color: $brand-primary;
            }
    }

    .letterspacing1{
        letter-spacing: 2px;
    }



      .frame-1{
          padding:20px;
          margin:20px;

          @media (max-width:$screen-xs-min) {
                padding:10px;
                margin:10px;
             }
      }

      .frame-2{
          padding:20px;

          @media (max-width:$screen-xs-min) {
                padding:10px;
             }
      }

      .frame-3{
          margin:20px;

          @media (max-width:$screen-xs-min) {
                margin:10px;
             }
      }

      .smap {

        .map {
            padding-bottom: 500px;
        }

        .block1 {
            padding: 3%;
           color: white;
            background: $brand-primary;
        }
    }

    .img-rounded{
            border-radius: 15px;
    }

    .blockquote-2 {
      padding: 15px 30px;
      margin: 0 0 30px;
      font-size: 16px;
      border-left: 5px solid $brand-primary;
      margin-left:25px;
      background-color: white;
      border-radius:0 15px 15px 0px;
      //   box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

  //.nav>li>a {
      //padding: 10px 15px;

  //	@media (min-width:1280px) {
      //	font-size: 12px;
      //	padding: 10px 10px;
      //}
  //}

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////



.s-header-1{
    margin-top:0px;
    min-height:95vh;
    position: relative;

    .transition{
        position: absolute;
        bottom:-2px;
        left:0;
        right:0;
        height:91px;

        background: url(../images/charte/brush1.png) no-repeat center;
        background-size: cover;
    }

}

.actu .well{

    border: solid 3px $brand-primary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.rochelle1{
    .block2{
        min-height:50vh;
        background: url(../images/charte/rochelle.jpg) no-repeat center left;
        background-size: cover;
        border-radius:0 20px 20px 0;
    }
}

.roche1{
    @extend .rochelle1;

    .block2{
        background: url(../images/charte/roche.jpg) no-repeat center left;
        background-size: cover;
    }
}

.rochelle2{

    .block2{
        // padding-right:20px;
        // background: url(../images/charte/monde2.jpg) no-repeat center left ;
        //  background-size: cover;
    }

    .well2{
            // background: rgba(255, 255, 255, 0.99);
            // border-left: solid 5px $brand-second;
    }

}

.bg-perso{
    background: url(../images/charte/rochelle2.jpg) no-repeat center;
    background-size: cover;
}

.bg-perso-2{
    background: url(../images/charte/roche2.jpg) no-repeat center;
    background-size: cover;
}

.bg-perso-3{
        background: rgba($brand-primary, 0.3);
        transition: all 1s;


        &:hover{
            background: rgba($brand-primary, 0.7);
            transition: all 1s;
        }

}
.inter .h1{
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.html-detail{
    border: solid 2px $brand-primary;
}

.contact{
    background: url(../images/charte/contact1.jpg) no-repeat center;
    background-size: cover;

    @media (max-width:1440px) {
        background: url(../images/charte/contact2.jpg) no-repeat center;
        background-size: cover;
    }

}


.bonCadeaux-details {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: .5em .5em 0;
}

.bonCadeaux-summary {
    font-weight: bold;
    margin: -.5em -.5em 0;
    padding: .5em;
}

.bonCadeaux-details[open] {
    padding: .5em;
}

.bonCadeaux-details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: .5em;
}
