/* ==========================================================================
  Mini-caddie footer
  ========================================================================== */

.panel-mini-caddie-footer {
  margin-bottom: 1rem;
}

.mini-caddie-quant {
  display: inline-block;
  padding-left: 10px;
}
