//-- Positions

.relative {
  position: relative;
}

.p-static {
  position: static !important;
}

.sticky-top {
  position: sticky;
  top: 0;
}

.lg-sticky-top-6 {

  @media(min-width: $screen-lg-min) {
    position: sticky;
    top: 2rem;
  }
}
