
.tarifs-title {
  min-width: 100px;
}

.td-description p{
  margin: 0;
  text-align: left;
}

.tarifs-small {
  display: block;
  font-size: .75rem;
  line-height: 1.3;
}

// ==== Table responsive

.table {
  width: 100%;
  margin: 0 auto;
}

.table tr {
  vertical-align: top;
}

@media (max-width: 700px){
  .table thead {
    display: none;
  }
  
  .table tr {
    display: block;
    margin-bottom: 2rem;
  }

  .table td {
    display: block;
    text-align: right;
  }

  .table td::before {
    content: attr(data-label);
    float: left;
    text-align: left;
    font-weight: bold;
  }

  .td-description {

    &::before {
      float: none;
      width: 100%;
    }
  }
}


.table-title {
  font-weight: 500;
  background-color: #fff;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.table-price {
  font-weight: 800;
  display: inline-block;
  padding: 5px 10px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  width: 60px;
  
  &::after {
    font-family: "FontAwesome";
    font-weight: 300;
    content: "\f153";
    position: absolute;
    top: 5px;
    left: 55px;
    bottom: 0;
    right: 0;
    z-index: 0;
    background-color: #fff;
  }
}
